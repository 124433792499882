
import React, { useEffect, useState, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import useSWR from 'swr';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

// layouts
import Navbar from './layouts/Navbar';
import Footer from './layouts/Footer';
import SkeletonLoader from './pages/Loader/SkeletonLoader';
import WhatsappChatPlugin from './pages/whatsappChatPlugin/whatsappChatPlugin';
import Graduates from './pages/Graduates/Graduates';
import SustainableEngineering from './pages/SustainableEngineering/SustainableEngineering';
import SustainableForm from './pages/SustainableEngineering/SustainableForm';
import ProtectedRoute from './components/ProtectedRoute';
// Lazy-loaded pages 
const Home = React.lazy(() => import('./pages/home/Home'));
const SingleFeatures = React.lazy(() => import('./pages/news/SingleFeatures'));
const About = React.lazy(() => import('./pages/about/about'));
const InstituteBoardDirectors = React.lazy(() => import('./pages/about/InstituteBoardDirectors'));
const AcademicCouncils = React.lazy(() => import('./pages/about/AcademicCouncils'));
const Manger = React.lazy(() => import('./pages/about/Manger'));
const PdfAbout = React.lazy(() => import('./pages/about/Pdf'));
const EvidenceAndPolicy = React.lazy(() => import('./pages/about/EvidenceAndPolicy'));
const Department = React.lazy(() => import('./pages/department/department'));
const Doctor = React.lazy(() => import('./pages/department/doctor'));
const Doctors = React.lazy(() => import('./pages/department/doctors'));
const CoursesPage = React.lazy(() => import('./pages/department/Courses'));
const HeadDepartment = React.lazy(() => import('./pages/department/Head_Department'));
const Workshops = React.lazy(() => import('./pages/department/workshops'));
const MasterDoctoral = React.lazy(() => import('./pages/department/Master_doctoral'));
const SearchProject = React.lazy(() => import('./pages/department/search_project'));
const ScientificTrips = React.lazy(() => import('./pages/department/Scientific_trips'));
const Rewords = React.lazy(() => import('./pages/department/rewords'));
const Decisions = React.lazy(() => import('./pages/department/decisions'));
const PdfDepartment = React.lazy(() => import('./pages/department/Pdf'));
const Student = React.lazy(() => import('./pages/student/student'));
const Pdfstudent = React.lazy(() => import('./pages/student/Pdf'));
const Services = React.lazy(() => import('./pages/student/services'));
const Units = React.lazy(() => import('./pages/unit/units'));
const PdfUnit = React.lazy(() => import('./pages/unit/Pdf'));
const BoardStructure = React.lazy(() => import('./pages/unit/BoardStructure'));
const ManagerDeputy = React.lazy(() => import('./pages/unit/ManagerDeputy'));
const News = React.lazy(() => import('./pages/news/news'));
const SingleNews = React.lazy(() => import('./pages/news/singleNews'));
const Pdf = React.lazy(() => import('./pages/services/Pdf'));
const Survey = React.lazy(() => import('./pages/services/survey'));
const MilitaryEducation = React.lazy(() => import('./pages/services/MilitaryEducation'));
const SuggestionsComplaints = React.lazy(() => import('./pages/services/SuggestionsComplaints'));
const Important = React.lazy(() => import('./pages/services/Important'));
const Contact = React.lazy(() => import('./pages/contacts/contact'));
const Job = React.lazy(() => import('./pages/contacts/Job'));
const NotFound = React.lazy(() => import('./pages/404/404'));
// import FAQ from './pages/student/FAQ';

function App() {
  const { t, i18n } = useTranslation();
  // لينك ال api 
  // const api = 'http://localhost/Bagour_new2/';
  // const api = 'http://192.168.1.165/kfs/';
  const api = 'https://mis.kfs-hiet.edu.eg/';

  const [langState, setLangState] = useState(i18n.language === 'ar');
  const { data: settiengs } = useSWR(`${api}api/website/daycare_settiengs`, fetchFromApi);
  const { data: departments } = useSWR(`${api}api/website/departments`, fetchFromApi);
  const { data: classification } = useSWR(`${api}api/website/newsClassification`, fetchFromApi);
  const { data: units } = useSWR(`${api}api/website/units`, fetchFromApi);
  const textDescription = langState ?
    ' المعهد العالي للهندسه والتكنولوجيا بكفر الشيخ  هو صرح تعليمي متميز ذو بيئة علمية خلاقة مبنية على الإبداع والتفاعل  من خلال إعتماد المعايير الوطنية والدولية لجودة التعليم وتطوير خططه ومناهجه بما يتلاءم مع التطورات العالمية لتلبية احتياجات المجتمع المحلي والإقليمي والدولي.' :
    'The High Institute of Engineering and Technology in Kafr El-Sheikh is a distinguished educational institution with a creative scientific environment built on innovation and interaction. It adopts national and international standards for quality education and develops its plans and curricula to align with global advancements, meeting the needs of the local, regional, and international communities.'
  const commonProps = { api, t, i18n, langState, textDescription };

  useEffect(() => {
    document.documentElement.lang = i18n.language;
    setLangState(i18n.language === 'ar');
  }, [i18n.language]);
  return (
    <React.Fragment>
      {settiengs && departments && classification && units && (
        <React.Fragment>
          <Navbar settiengs={settiengs} {...commonProps} departments={departments} units={units} classification={classification} />
          <Suspense fallback={<SkeletonLoader />}>
            <main>
              <Routes>
                <Route path="/" element={<Home {...commonProps} settiengs={settiengs} units={units} departments={departments} classification={classification} />}></Route>
                
                <Route path="/about/">  {/*about  */}
                  <Route index element={<About {...commonProps} settiengs={settiengs} departments={departments} classification={classification} />}></Route>
                  <Route path="InstituteBoardDirectors" element={<InstituteBoardDirectors {...commonProps} settiengs={settiengs} />}></Route>
                  <Route path="AcademicCouncils" element={<AcademicCouncils {...commonProps} settiengs={settiengs} />}></Route>
                  <Route path="Evidence and policy" element={<EvidenceAndPolicy {...commonProps} units={units} langState={langState} />}></Route>
                  <Route path=":name" element={<Manger {...commonProps} settiengs={settiengs} />}></Route>
                  <Route path="Pdf/:namePdf" element={<PdfAbout {...commonProps} settiengs={settiengs} />}></Route>
                </Route>

                <Route path="/department/"> {/* Department */}
                  <Route path=":name" element={<Department {...commonProps} departments={departments} />}></Route>
                  <Route path=":name/doctors" element={<Doctors {...commonProps} departments={departments} />}></Route>
                  <Route path=":name/AssistantDoctor" element={<Doctors {...commonProps} departments={departments} />}></Route>
                  <Route path=":name/Courses" element={<CoursesPage {...commonProps} departments={departments} />}></Route>
                  <Route path=":name/head_Department" element={<HeadDepartment {...commonProps} departments={departments} />}></Route>
                  <Route path=":name/workshops" element={<Workshops {...commonProps} departments={departments} />}></Route>
                  <Route path=":name/master_doctoral" element={<MasterDoctoral {...commonProps} departments={departments} />}></Route>
                  <Route path=":name/search_project" element={<SearchProject {...commonProps} departments={departments} />}></Route>
                  <Route path=":name/rewords" element={<Rewords {...commonProps} departments={departments} />}></Route>
                  <Route path=":name/scientific_trips" element={<ScientificTrips {...commonProps} departments={departments} />}></Route>
                  <Route path=":name/decisions" element={<Decisions {...commonProps} departments={departments} />}></Route>
                  <Route path=":name/:namePdf" element={<PdfDepartment {...commonProps} departments={departments} />}></Route>
                </Route>

                <Route path="/student/">  {/*  student   */}
                  <Route index element={<Student />}></Route>
                  <Route path=":name" element={<Pdfstudent {...commonProps} />}></Route>
                  <Route path="services/:name" element={<Services {...commonProps} />}></Route>
                </Route>

                <Route path="/news/">  {/* news */}
                  <Route index element={<News {...commonProps} classification={classification} />}></Route>
                  <Route path=":category" element={<News {...commonProps} classification={classification} />}></Route>
                  <Route path=":category/:name" element={<SingleNews {...commonProps} classification={classification} />}></Route>
                </Route>

                <Route path="/unit/">  {/* unit */}
                  <Route path=":name" element={<Units {...commonProps} units={units} langState={langState} />}></Route>
                  <Route path=":name/BoardStructure" element={<BoardStructure {...commonProps} units={units} />}></Route>
                  <Route path=":name/ManagerDeputy" element={<ManagerDeputy {...commonProps} units={units} />}></Route>
                  <Route path=":name/:namePdf" element={<PdfUnit {...commonProps} units={units} />}></Route>
                </Route>

                <Route path="/services/">    {/* services */}
                  <Route path="survey" element={<Survey {...commonProps} />}></Route>
                  <Route path="MilitaryEducation" element={<MilitaryEducation {...commonProps} />}></Route>
                  <Route path="Suggestions and complaints" element={<SuggestionsComplaints {...commonProps} settiengs={settiengs} />}></Route>
                  <Route path=":name" element={<Pdf {...commonProps} />}></Route>
                </Route>
            

                <Route path="/important/">  {/* services */}
                  <Route path=":name" element={<Important   {...commonProps} />}></Route>
                </Route>

                {/* الخريجون   */}
                {/* <Route path="/Graduates/" element={<Graduates/>}></Route> */}
                <Route path="/conferences/" >
                <Route path=":name" element={<SustainableEngineering/>}></Route>
                
                </Route>
                <Route path="/SustainableForm" element={
                  <ProtectedRoute>
                  <SustainableForm/>
                </ProtectedRoute>}>
                </Route>
               
                {/* library */}
                {/* <Route path="/library" element={<Library />}></Route>
                      <Route path="/available" element={<Available />}></Route>
                      <Route path="/available/form" element={<AvailableForm />}></Route>
                      <Route path="/borrow" element={<Borrow />}></Route>
                      <Route path="FAQ" element={<FAQ   {...commonProps} />}></Route>
                    <Route path="researches" element={<FAQ {...commonProps} />}></Route> */}
                <Route path="/features/:id/:name" element={<SingleFeatures {...commonProps} />}></Route>
                <Route path="/doctor/:name" element={<Doctor {...commonProps} departments={departments} />}></Route>
                <Route path="/contact" element={<Contact {...commonProps} settiengs={settiengs} />}></Route>
                <Route path="job" element={<Job {...commonProps} settiengs={settiengs} />}></Route>
                <Route path="*" element={<NotFound {...commonProps} />}></Route>
              </Routes>
            </main>
          </Suspense>
          <WhatsappChatPlugin settiengs={settiengs} {...commonProps} />
          <Footer settiengs={settiengs} {...commonProps} />
        </React.Fragment>
      )}
    </React.Fragment >
  );
}

// Function to fetch data using axios
const fetchFromApi = async (url) => {
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error);
    throw error;
  }
};
export default (App);
