import React, { useState } from 'react';
import modalImg from '../assets/imgs/modal-img.png'
const MyModalComponent = () => {
    const [showModal, setShowModal] = useState(true);
    const handleClose = () => {
        setShowModal(false);
    };
    return (
        <div className='modal-content-home'>
            {showModal && (
                <div className="modal fade show" style={{ display: 'block' }} aria-labelledby="exampleModalLabel" aria-hidden="false">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header position-relative d-flex justify-content-end">
                                <button type="button" class="btn btn-danger btn-skip" onClick={handleClose}> تخطى </button>
                            </div>
                            <div className="modal-body">
                                <img src={modalImg} alt="modalImg" className='img-fluid modalImg' />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MyModalComponent;
