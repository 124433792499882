import { useNavigate } from "react-router-dom";

const ProtectedRoute = (props) => {
    const savedId = localStorage.getItem('savedId');
    const navigate = useNavigate();
    
    if (savedId) {
        return props.children;
    } else {
        navigate("/");
    }
}

export default ProtectedRoute;    