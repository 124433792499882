import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Countdown = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    const calculateTimeLeft = () => {
      const difference = +new Date(targetDate) - +new Date();
      let timeLeft = {};

      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
       
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60)
        };
      }

      return timeLeft;
    };

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  const timeUnits = ['days',  'hours', 'minutes', 'seconds'];

  return (
    <div className="countdown position-absolute d-flex justify-content-around align-items-center">
      {timeUnits.map((unit) => (
        <div key={unit} className="countdown-item">
          <p className="fs-4 text-dark">{t(unit)}</p>
          <h4 className="text-center">{timeLeft[unit] || 0}</h4>
        </div>
      ))}
    </div>
  );
};

export default Countdown;