import React from 'react';
import style from '../../components/aside/aside.module.css'
function Graduates() {
  return (
    <>
      <div className="  mt-5 pt-5 Graduates-sections">
        <div className="container ">
          <div className="row dir-ltr">
            <div className="col-lg-8 col-sm-12 " style={{ direction: "rtl" }}>
              <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active dir-rtl" id="list-home" role="tabpanel" aria-labelledby="list-home-list">
                  <div class="col-lg-12 single-left">
                    <div class="d-flex justify-content-center align-items-center">
                      <img src={require("../../assets/imgs/logo.webp")} alt="" style={{ width: '150px' }} />
                    </div>


                    <div class="card">
                      <div class="card-header text-center bg-success text-white ">
                        <i class=" ms-2 fa-regular fa-hand-point-left" aria-hidden="true"></i>

                        يمكنك التواصل مع وحدة شئون الخريجين من خلال رقم المعهد (0108052363)                       </div>

                      <div class="card-body">
                        <div class="card">
                          <div class="card-header text-center bg-warning text-black ">
                            <i class=" ms-2 fa-solid fa-list-check" aria-hidden="true"></i>
                            أنواع الشهادات التى تقوم الوحدة باستخراجها لخريجيها


                          </div>
                          <ul class="list-group list-group-flush">
                            <li class="list-group-item">1.شهادة مؤقتة عادية باللغة العربية	 </li>
                            <li class="list-group-item">2.شهادة مؤقتة عادية باللغة الانجليزية	  </li>
                            <li class="list-group-item">3.بيان تقديرات باللغة العربية	</li>
                            <li class="list-group-item">4.بيان تقديرات باللغة الانجليزية	 </li>
                            <li class="list-group-item">5.شهادة مؤقتة معادلة من المجلس الأعلى للجامعات (عربى) فقط  	 </li>
                            <li class="list-group-item">6.شهادة تخرج (دائمة) مرة واحدة بعد 3 سنوات من التخرج  	 </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="tab-pane fade" id="list-profile" role="tabpanel" aria-labelledby="list-profile-list">
                  <div class="col-lg-12 single-left" style={{ direction: "rtl" }}>

                    <div class="d-flex justify-content-center align-items-center">
                      <img src={require("../../assets/imgs/logo.webp")} alt="" style={{ width: '150px' }} />
                    </div>

                    <div class="card border-secondary mb-3  ">
                      <div class="card-header text-center bg-success text-white ">
                        <i class=" ms-2 fas fa-graduation-cap " aria-hidden="true"></i>

                        وحدة شئون الخريجين

                      </div>
                      <div class="card-body text-secondary">

                        <div class="container">
                          <div class="my-3">
                            <h3 class="my-3 text-center">عن الوحدة</h3>

                            <p class="text-justify">تقوم وحدة الخريجين بمتابعة شؤون الخريجين بالتعرف على جهات أعمالهم ، وطبيعة  وظائفهم وحصرها ، وذلك لمعرفة مدى توافق تخصصاتهم مع كتطلبات سوق العمل، و تلقي مقترحاتهم حول البرامج التعليمية المتخصصة لتطويرها من خلال التنسيق مع كل من الأقسام المعنية ، وحدة التخطيط والتطوير ، وحدة ضمان الجودة.
                            </p>

                          </div>
                        </div>
                      </div>

                    </div>
                  </div>                </div>
                <div className="tab-pane fade" id="list-messages" role="tabpanel" aria-labelledby="list-messages-list">
                  <div class="col-lg-12 single-left" style={{ direction: "rtl" }}>

                    <div class="d-flex justify-content-center align-items-center">
                      <img src={require("../../assets/imgs/logo.webp")} alt="" style={{ width: '150px' }} />
                    </div>

                    <div class="card border-secondary mb-3  ">
                      <div class="card-header text-center bg-success text-white ">
                        <i class=" ms-2 fa fa-bullseye" aria-hidden="true"></i>
                        الرؤية والرسالة
                      </div>
                      <div class="card-body text-secondary">

                        <div class="container">
                          <div class="my-3">
                            <h3 class="my-3 text-center">رؤية الوحدة</h3>

                            <p class="text-justify">دعم وتعزيز المجتمع بخريجين متميزين علميا ، ومهنيا ، وبحثيا في القطاع الهندسي ومؤهلين لسوق العمل لمواكبة التطور المستمر.</p>

                          </div>
                          <div class="my-3">
                            <h3 class="my-3 text-center">رسالة الوحدة</h3>

                            <p class="text-justify">تهدف الوحدة الي توطيد علاقات متميزة بين والمعهد و خريجيه والمجتمع المحلي والاقليمي والدولي في القطاع الهندسي ، كما تسعي الوحدة على دعم جودة ومهارة وجدارات الخريجين من خلال التواصل المستمر ، والبرامج التدريبية ، والبحوث العلمية التي يتشارك فيها الخريجون وأعضاء هيئة التدريس في المعهد لتأهيلهم لسوق العمل ومواكبة التطور المستمر.</p>

                          </div>


                        </div>


                      </div>


                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="list-goals" role="tabpanel" aria-labelledby="list-goals-list">
                <div class="col-lg-12 single-left"  style={{ direction: "rtl" }}>
                    
                <div class="d-flex justify-content-center align-items-center">
                      <img src={require("../../assets/imgs/logo.webp")} alt="" style={{ width: '150px' }} />
                    </div>

                    <div class="card">
                        <div class="card-header text-center bg-success text-white ">
                            <i class=" ms-2 fas fa-crosshairs" aria-hidden="true"></i>
                          أهداف الوحدة
                        </div>
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item">1.	زيادة التواصل مع خريجي المعهد عبر وسائل التواصل المختلفة </li>
                          <li class="list-group-item">2.	تعزيز علاقة الخريجين بالمعهد وتنظيم لقاءات دورية معهم </li>
                          <li class="list-group-item">3.	التوسع في إقامة الندوات التوعوية والتربوية والثقافية لدعم المشاركة المجتمعية من خلال دعوة الاطراف المعنية.</li>
                          <li class="list-group-item">4.	قياس  مدى رضا الجهات المستفيدة من خريجي المعهد في القطاعين العام والخاص </li>
                        </ul>
                    </div>
        
             </div>
                </div>
                <div className="tab-pane fade" id="list-tasks" role="tabpanel" aria-labelledby="list-tasks-list">
                <div class="col-lg-12 single-left"  style={{ direction: "rtl" }}>
                    
                        

                <div class="d-flex justify-content-center align-items-center">
                      <img src={require("../../assets/imgs/logo.webp")} alt="" style={{ width: '150px' }} />
                    </div>

                    <div class="card">
                        <div class="card-header text-center bg-success text-white ">
                            <i class=" ms-2 fas fa-sticky-note" aria-hidden="true"></i>
                          مهام الوحدة
                        </div>
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item">	 1.	إنشاء قاعدة بيانات خاصة بالخريجين لتحقيق الأهداف المرجودة للوحدة.</li>
                          <li class="list-group-item">2.	التواصل مع الخريجين بعد تخرجهم من المعهد.</li>
                          <li class="list-group-item">3.	تلقّي مقترحات الخريجين حول برامج المعهد .</li>
                          <li class="list-group-item">4.	متابعة دعوة الخريجين للمشاركة في أنشطة المعهد ودعمها .	</li>
                          <li class="list-group-item">	5.	إقامة لقاء سنوي للخريجين.   </li>
                          <li class="list-group-item">6.	اتخاذ الاجراءات اللازمة نحو عرض مقترحات التحسين المقترحة من الخريجين	</li>
                        </ul>
                    </div>
        
        
        
        
                    </div>
                </div>
                <div className="tab-pane fade" id="list-manager" role="tabpanel" aria-labelledby="list-manager-list">
                  <h1>مدير الوحدة</h1>
                </div>
                <div className="tab-pane fade" id="list-login" role="tabpanel" aria-labelledby="list-login-list">
                  <h1>تسجيل دخول</h1>
                </div>
                <div className="tab-pane fade" id="list-news" role="tabpanel" aria-labelledby="list-news-list">
                  <h1>الاخبار</h1>
                </div>
                <div className="tab-pane fade" id="list-alumni" role="tabpanel" aria-labelledby="list-alumni-list">
                  <h1>الخريجين</h1>
                </div>
                <div className="tab-pane fade" id="list-faq" role="tabpanel" aria-labelledby="list-faq-list">
                  <h1>الاسئلة الشائعة</h1>
                </div>
                <div className="tab-pane fade" id="list-album" role="tabpanel" aria-labelledby="list-album-list">
                  <h1>البوم الصور</h1>
                </div>
                <div className="tab-pane fade" id="list-inquiry" role="tabpanel" aria-labelledby="list-inquiry-list">
                  <h1>الاستعلام عن خريج</h1>
                </div>
                <div className="tab-pane fade" id="list-certificate" role="tabpanel" aria-labelledby="list-certificate-list">
                  <h1>طلب الحصول على شهادة</h1>
                </div>
                <div className="tab-pane fade" id="list-contact" role="tabpanel" aria-labelledby="list-contact-list">
                  <h1>اتصل بنا</h1>
                </div>

              </div>
            </div>
            <div className="col-lg-3  col-sm-12 mb-5 aside-Graduates">
              <div className="list-group" id="list-tab" role="tablist">
                <div className={`${style.aside} rounded  px-3 mt-2`}>
                  <img className={`${style.img}  w-100`} src={require("../../assets/imgs/logo.webp")}
                    alt="" />

                  <a
                    className={`${style.btn} mb-2 rounded border list-group-item list-group-item-action active`}
                    id="list-home-list"
                    data-bs-toggle="list"
                    href="#list-home"
                    role="tab"
                    aria-controls="list-home"
                  >
                    الرئيسية
                  </a>
                  <a
                    className={`${style.btn} mb-2 rounded border list-group-item list-group-item-action`}
                    id="list-profile-list"
                    data-bs-toggle="list"
                    href="#list-profile"
                    role="tab"
                    aria-controls="list-profile"
                  >
                    عن الوحدة
                  </a>
                  <a
                    className={`${style.btn} mb-2 rounded border list-group-item list-group-item-action`}
                    id="list-messages-list"
                    data-bs-toggle="list"
                    href="#list-messages"
                    role="tab"
                    aria-controls="list-messages"
                  >
                    الرؤية والرسالة
                  </a>
                  <a
                    className={`${style.btn} mb-2 rounded border list-group-item list-group-item-action`}
                    id="list-goals-list"
                    data-bs-toggle="list"
                    href="#list-goals"
                    role="tab"
                    aria-controls="list-goals"
                  >
                    أهداف الوحدة
                  </a>
                  <a
                    className={`${style.btn} mb-2 rounded border list-group-item list-group-item-action`}
                    id="list-tasks-list"
                    data-bs-toggle="list"
                    href="#list-tasks"
                    role="tab"
                    aria-controls="list-tasks"
                  >
                    مهام الوحدة
                  </a>
                  <a
                    className={`${style.btn} mb-2 rounded border list-group-item list-group-item-action`}
                    id="list-manager-list"
                    data-bs-toggle="list"
                    href="#list-manager"
                    role="tab"
                    aria-controls="list-manager"
                  >
                    مدير الوحدة
                  </a>
                  <a
                    className={`${style.btn} mb-2 rounded border list-group-item list-group-item-action`}
                    id="list-login-list"
                    data-bs-toggle="list"
                    href="#list-login"
                    role="tab"
                    aria-controls="list-login"
                  >
                    تسجيل دخول
                  </a>
                  <a
                    className={`${style.btn} mb-2 rounded border list-group-item list-group-item-action`}
                    id="list-news-list"
                    data-bs-toggle="list"
                    href="#list-news"
                    role="tab"
                    aria-controls="list-news"
                  >
                    الاخبار
                  </a>
                  <a
                    className={`${style.btn} mb-2 rounded border list-group-item list-group-item-action`}
                    id="list-alumni-list"
                    data-bs-toggle="list"
                    href="#list-alumni"
                    role="tab"
                    aria-controls="list-alumni"
                  >
                    الخريجين
                  </a>
                  <a
                    className={`${style.btn} mb-2 rounded border list-group-item list-group-item-action`}
                    id="list-faq-list"
                    data-bs-toggle="list"
                    href="#list-faq"
                    role="tab"
                    aria-controls="list-faq"
                  >
                    الاسئلة الشائعة
                  </a>
                  <a
                    className={`${style.btn} mb-2 rounded border list-group-item list-group-item-action`}
                    id="list-album-list"
                    data-bs-toggle="list"
                    href="#list-album"
                    role="tab"
                    aria-controls="list-album"
                  >
                    البوم الصور
                  </a>
                  <a
                    className={`${style.btn} mb-2 rounded border list-group-item list-group-item-action`}
                    id="list-inquiry-list"
                    data-bs-toggle="list"
                    href="#list-inquiry"
                    role="tab"
                    aria-controls="list-inquiry"
                  >
                    الاستعلام عن خريج
                  </a>
                  <a
                    className={`${style.btn} mb-2 rounded border list-group-item list-group-item-action`}
                    id="list-certificate-list"
                    data-bs-toggle="list"
                    href="#list-certificate"
                    role="tab"
                    aria-controls="list-certificate"
                  >
                    طلب الحصول على شهادة
                  </a>
                  <a
                    className={`${style.btn} mb-2 rounded border list-group-item list-group-item-action`}
                    id="list-contact-list"
                    data-bs-toggle="list"
                    href="#list-contact"
                    role="tab"
                    aria-controls="list-contact"
                  >
                    اتصل بنا
                  </a>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Graduates;
