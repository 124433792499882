import { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Toast } from 'primereact/toast'; // Import
import { useTranslation } from 'react-i18next';
import reserch_form from "../../assets/imgs/reserch_form.png"
const SustainableForm = () => {
    const savedId = localStorage.getItem('savedId');
    console.log(savedId); // This will log the saved ID to the console
    const { t } = useTranslation()
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        degree: '',
        title: '',
        abstract: null
    });
    const [captchaValue, setCaptchaValue] = useState(null);
    const toast = useRef(null);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'abstract') {
            setFormData({ ...formData, [name]: files[0] });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };
    const validateForm = () => {
        const { name, email, phone } = formData;
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const phonePattern = /^\d{11}$/;

        if (!name.trim()) {
            toast.current.show({ severity: 'warn', summary: 'Validation Error', detail: 'Name is required.', life: 3000 });
            return false;
        }

        if (!emailPattern.test(email)) {
            toast.current.show({ severity: 'warn', summary: 'Validation Error', detail: 'Please enter a valid email address.', life: 3000 });
            return false;
        }

        if (!phonePattern.test(phone)) {
            toast.current.show({ severity: 'warn', summary: 'Validation Error', detail: 'Phone number must be 11 digits.', life: 3000 });
            return false;
        }

        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!captchaValue) {
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Please complete the reCAPTCHA', life: 3000 });
            return;
        }

        if (!validateForm()) {
            return;
        }

        const apiUrl = `https://mis.kfs-hiet.edu.eg/api/website/sustainable_engineering/store/${savedId}?Name=${formData.name}&Email=${formData.email}&Phone=${formData.phone}&Academic_degree=${formData.degree}&Paper_title=${formData.title}`;

        const data = new FormData();
        data.append('Abstract', formData.abstract);

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                body: data,
            });

            if (response.ok) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Research paper submitted successfully!', life: 3000 });
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    degree: '',
                    title: '',
                    abstract: null

                });
                setCaptchaValue(false);
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to submit the research paper.', life: 3000 });
            }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'An error occurred. Please try again later.', life: 3000 });
        }
    };

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    };

    return (
        <div className="container" style={{ marginTop: "210px" }}>
            <div className="row">
                <div className="col-12 col-md-6">
                    <img src={reserch_form} className='img-fluid' alt="reserch_form" title='reserch_form' />
                </div>
                <div className="col-12 col-md-6">
                    <Toast ref={toast} />
                    <h2 className="text-center">{t("submit_research_paper")}</h2>
                    <form onSubmit={handleSubmit} className='row'>
                        <div className="mb-3 col-12 col-md-6">
                            <label htmlFor="name" className="form-label">{t("name")}</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                className="form-control"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3 col-12 col-md-6">
                            <label htmlFor="email" className="form-label">{t("mail")}</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                className="form-control"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3 col-12 col-md-6">
                            <label htmlFor="phone" className="form-label">{t("phone")}</label>
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                className="form-control"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3 col-12 col-md-6">
                            <label htmlFor="degree" className="form-label">{t("academic_degree")}</label>
                            <select
                                id="degree"
                                name="degree"
                                className="form-control"
                                value={formData.degree}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select your degree</option>
                                <option value="0">Professor</option>
                                <option value="1">Assistant Professor</option>
                                <option value="2">Lecturer</option>
                                <option value="3">Teaching assistant</option>

                            </select>
                        </div>
                        <div className="mb-3 col-12 col-md-6">
                            <label htmlFor="title" className="form-label">{t("paper_title")}</label>
                            <input
                                type="text"
                                id="title"
                                name="title"
                                className="form-control"
                                value={formData.title}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3 col-12 col-md-6">
                            <label htmlFor="abstract" className="form-label">{t("upload_abstract")}</label>
                            <input
                                type="file"
                                id="abstract"
                                name="abstract"
                                className="form-control"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3 col- ">
                            <ReCAPTCHA
                                className='w-100'
                                sitekey="6LcbQmUpAAAAAJ2cFfxWezlebr1-aGCCDuiwSYIh"
                                onChange={handleCaptchaChange}
                            />
                        </div>
                        <div className="mb-3 col-4">
                            <button type="submit" className="btn btn-primary w-100">
                                {t("send")}
                            </button>
                        </div>

                    </form>
                </div>

            </div>
        </div>

    );
};


export default SustainableForm