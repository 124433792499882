// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*  */
.aside_aside__CxmJe {
  position: sticky;
  top: 85px;
  height: 100%;
  background: linear-gradient(142deg, #fff, #e7e7e7);
  box-shadow: 0px 10px 5px 5px #dddddd;
}

@media (max-width: 768px) {
  .aside_aside__CxmJe {
    position: relative;
    top: 0;
  }
}

.aside_aside__CxmJe .aside_img__Yqeqa {
  height: 150px;
  aspect-ratio: 1/1;
  object-fit: contain;
}

.aside_aside__CxmJe .aside_btn__odCRK {
  border: 1px solid var(--primary);
  font-size: 14px;
  text-align: right;
  background-color: white;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  z-index: 1;
}

.aside_aside__CxmJe .aside_btn__odCRK::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 0;
  background-color: var(--main-color);
  z-index: -1;
  transition: all 0.8s
}

.aside_aside__CxmJe .aside_btn__odCRK:hover {
  color: #e8e8e8;
  border: 1px solid var(--main-color);
}

.aside_aside__CxmJe .aside_btn__odCRK:hover::before {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/aside/aside.module.css"],"names":[],"mappings":"AAAA,KAAK;AACL;EACE,gBAAgB;EAChB,SAAS;EACT,YAAY;EACZ,kDAAkD;EAClD,oCAAoC;AACtC;;AAEA;EACE;IACE,kBAAkB;IAClB,MAAM;EACR;AACF;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,gCAAgC;EAChC,eAAe;EACf,iBAAiB;EACjB,uBAAuB;EACvB,kBAAkB;EAClB,gBAAgB;EAChB,6BAA6B;EAC7B,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,YAAY;EACZ,QAAQ;EACR,mCAAmC;EACnC,WAAW;EACX;AACF;;AAEA;EACE,cAAc;EACd,mCAAmC;AACrC;;AAEA;EACE,WAAW;AACb","sourcesContent":["/*  */\n.aside {\n  position: sticky;\n  top: 85px;\n  height: 100%;\n  background: linear-gradient(142deg, #fff, #e7e7e7);\n  box-shadow: 0px 10px 5px 5px #dddddd;\n}\n\n@media (max-width: 768px) {\n  .aside {\n    position: relative;\n    top: 0;\n  }\n}\n\n.aside .img {\n  height: 150px;\n  aspect-ratio: 1/1;\n  object-fit: contain;\n}\n\n.aside .btn {\n  border: 1px solid var(--primary);\n  font-size: 14px;\n  text-align: right;\n  background-color: white;\n  position: relative;\n  overflow: hidden;\n  background-color: transparent;\n  z-index: 1;\n}\n\n.aside .btn::before {\n  content: \"\";\n  position: absolute;\n  top: 0;\n  right: 0;\n  height: 100%;\n  width: 0;\n  background-color: var(--main-color);\n  z-index: -1;\n  transition: all 0.8s\n}\n\n.aside .btn:hover {\n  color: #e8e8e8;\n  border: 1px solid var(--main-color);\n}\n\n.aside .btn:hover::before {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aside": `aside_aside__CxmJe`,
	"img": `aside_img__Yqeqa`,
	"btn": `aside_btn__odCRK`
};
export default ___CSS_LOADER_EXPORT___;
